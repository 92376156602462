/**
 * Permission Entry Statuses:
 *  Inactive    - Created but not active (e.g. when a password is created on an unpublished board)
 *  Active      - Permission can be used to access a board
 *  Removed     - The permission was active, but is no longer (e.g. unpublished or toggled off anyone can edit)
 *  Revoked     - The permission link has been reset and this permission can no longer be used to access the board.
 *              - NOTE: This is not currently available in the interface, but is functional.
 *  Error       - Something went wrong while updating the permission.
 */
export const PERMISSION_STATUS = {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE',
    REMOVED: 'REMOVED',
    REVOKED: 'REVOKED',
    ERROR: 'ERROR',
};

/**
 * Permission Entry Types.
 *  Public          - Standard permission created when publishing or enabling "anyone can edit"
 *  Temporary User  - Temporary permission that maps to a user ID so that a user that was invited can access
 *                      the board before they register.
 */
export const PERMISSION_ENTRY_TYPES = {
    PUBLIC: 'PUBLIC',
    TEMPORARY_USER: 'TEMPORARY_USER',
};

export const PASSWORD_PROTECTED_ACL_ID_PREFIX = '-PROTECTED-';
